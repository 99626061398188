class Mapping { // eslint-disable-line no-unused-vars

  // Replaces {SWN-<PROPNAME>} macros in url with the value for PROPNAME
  static _replaceMacros(url, replacements = {}) {
    const props = ['PAGEID', 'IID', 'RFR', 'RFR2', 'LEADID']

    for (let i = 0; i < props.length; i++) {
      if (replacements[props[i]])
        url = url.replace(`{SWN-${props[i]}}`, replacements[props[i]])
    }

    return url
  }

  static mapAvailabilities(avails = [], replacements) {
    return avails.map(x => {
      const leadid = uuid().replace(/-/g, ''),
        rawUrl = x.clkurl ? Mapping._replaceMacros(x.clkurl, replacements) : ''

      return {
        product: {
          id: x.pid || '',
          ean: x.ean || '',
          name: x.pname || '',
          img: x.pimgurl || '',
          thumbImgUrl: x.ptbimgurl || '',
          pkg: x.ppkg || '',
          containing: x.pcont || '',
          retailerPdtId: x.rpid || '',
          url: x.purl || '',
          available: x.av || false,
        },

        retailer: {
          id: x.rid || 0,
          code: x.rcode || '',
          name: x.rname || '',
          url: x.rurl || '',
          logo: x.rlogo || '', // default logo url
          logoId: x.rlogoid || '',
          affiliate: x.raff || false,
          affiliatePrgId: x.raffprgid || null,
          isTiedHouseLaw: x.isTiedHouseLaw || false,
        },

        store: {
          id: x.sid || '',
          name: x.sname || '',
          location: {
            address: x.saddr || '',
            zipCode: x.szc || '',
            city: x.scity || '',
            latitude: x.slat || 0.0,
            longitude: x.slng || 0.0,
          },
          amz: x.amz || false,
          typologyId: x.stid || 0,
          opening: x.sopening || '',
          openingStruct: x.sopeningstruct || null,
          homeDelivery: x.shd || false,
          homeDeliveryAll: x.shda || false,
          collect: x.scollect || false,
          drive: x.sdrive || false,
          brick: x.sbrick || false,
          url: x.surl || '',
          gmapUrl: x.sgmapurl || '',
          distance: x.sdst || 0.0,
          phone: x.sphonenumber || '',
          whatsapp: x.swhatsapp || '',
          tags: x.stags || null,
          imgUrl: x.simgurl || '',
          openingHours: Mapping._parseOpeningHours(x.sopeningschema),
          openingType: x.sopeningt || null,
          appointmentUrl: x.sappointmenturl || '',
        },
        nearestStore: null,

        price: {
          canShow: x.prok || false,
          amount: x.pr || 0.0,
          unitPrice: x.upr || 0.0,
          unitPriceLbl: x.uprlbl || '',
          refPrice: x.refpr || 0.0,
          promo: x.pmo || false,
          promoType: x.pmotype || 0,
          promoLbl: x.pmolbl || '',
          promoPct: x.pmopct || 0.0,
          promoAmount: x.pmoam || 0.0,
          currency: x.currency,
        },

        voucher: x.voucher || null,
        voucherNewPrice: x.voucherNewPrice,

        priority: x.priority || false,
        cartUrl: x.carturl || '',
        rawClkUrl: rawUrl, // redirect url with constant-value placeholders replaced
        clkUrl: rawUrl ? rawUrl.replace('{SWN-LEADID}', leadid) : '', // redirect url with leadid placeholder replaced by actual value
        clkUrlType: x.clkurlt || 0,
        clkUrlAffiliate: x.clkurlaff || false,
        leadid: leadid,
        lockdown: !!x.slockdown,
      }
    })
  }

  // product filters on a property are defined using the base model, not the more explicit one defined in this widget.
  // so let's map possible values to what's actually used.
  static mapPdtFilters(values) {
    if (values == null) return []

    return values.map(x => {
      let value

      switch (x) {
        case 'pbr': value = 'brand'; break
        case 'pname': value = 'name'; break
        case 'ppckg': value = 'pkg'; break
        case 'pdescr': value = 'description'; break
        default: value = x
      }

      return value
    })
  }

  // opening hours values are split to show separately days and hours
  static _parseOpeningHours(values) {
    if (!values || values.length === 0) return null

    return values.map(line => line.split(' '))
  }
}
